import { Formik } from 'formik';
import * as Yup from "yup";
import React, { useState } from 'react';
import { Button, Col, Form, Input, InputGroup, Label, Row, Spinner } from 'reactstrap';
import { PostData } from '../../../services/FetchData';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';

const CollectWebsite = (props) => {
	const [redirect, setRedirect]	= useState(false);

	const saveStoreInformation	= (values, actions) => {
		PostData("ms1", "api/v1/website/saveStore", {...values, storeType: "magento"}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setRedirect(true)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			actions.setSubmitting(false)
		})
	}

	if( redirect ){
		return <Navigate to="/user/dashboard" replace />
	}

	return (
		<>
			<section className='choosePlatformHeader'>
				<div className='container'>
					<Row className="align-items-center justify-content-center">
						<Col xs={"auto"}>
							<h2 className='mt-5'>Store Information</h2>
						</Col>
					</Row>
				</div>
			</section>
			<section className='shopifyAppInstallationGuide mt-5'>
				<div className='container'>
					<Row className='align-items-center justify-content-center'>
						<Col xs={12} lg={6}>
							<div className='storeInformationWrapper'>
								<Formik
									initialValues={{ storeName: "", storeURL: ""}}
									onSubmit={(values, actions) => {
										saveStoreInformation(values, actions)
									}}
									validationSchema={Yup.object().shape({
										storeName: Yup.string().required("Store name is required"),
										storeURL: Yup.string().required("Store url is required").url("Please enter valid store url")
									})}
								>
									{props => {
										const {
											values,
											touched,
											errors,
											isSubmitting,
											//setSubmitting,
											handleChange,
											handleBlur,
											handleSubmit
										} = props;
										return (
											<Form role="form" onSubmit={handleSubmit} noValidate>
												<div className={`position-relative mb-5 ${(errors.storeName && touched.storeName && "error_field")}`}>
													<Label className="input-group-alternative-label" >
														Store Name
													</Label>
													<InputGroup className="input-group-alternative">
														{/* <InputGroupText>
															<span className="material-icons-outlined">mail</span>
														</InputGroupText> */}
														<Input
															placeholder="Store Name"
															type="text"
															name="storeName"
															value={values.storeName}
															onChange={handleChange}
															onBlur={handleBlur}
															autofill='false'
															className={
																"col" || (errors.storeName && touched.storeName && "error")
															}
														/>
													</InputGroup>
													{errors.storeName && touched.storeName && (
														<div className="input-feedback">{errors.storeName}</div>
													)}
												</div>
												<div className={`position-relative mb-5 ${(errors.storeURL && touched.storeURL && "error_field")}`}>
													<Label className="input-group-alternative-label" >
														Store URL
													</Label>
													<InputGroup className="input-group-alternative">
														{/* <InputGroupText>
															<span className="material-icons-outlined">lock_open</span>
														</InputGroupText> */}
														<Input
															placeholder="Store URL"
															type={"text"}
															name="storeURL"
															value={values.storeURL}
															onChange={handleChange}
															onBlur={handleBlur}
															className={
																"col" ||
																(errors.storeURL && touched.storeURL && "error")
															}
															autofill="false"
														/>
													</InputGroup>
													{errors.storeURL && touched.storeURL && (
														<div className="input-feedback">{errors.storeURL}</div>
													)}
												</div>
												<div className="d-flex justify-content-center">
													{isSubmitting ? (
														<Button className="cta authCta" color="primary" type="button" disabled>
															<Spinner
																className="white mr-1"
																size="sm"
																color="light"
															/>
															<span className="ml-2 d-inline-block">Loading</span>
														</Button>
													) : (
														<Button className=" cta authCta " color="primary" type="submit">Save</Button>
													)}											
												</div>												
											</Form>)
									}}
								</Formik>
							</div>
						</Col>
					</Row>
				</div>
			</section>
		</>
	);
}

export default CollectWebsite;