import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { authRoutes } from '../Routes.js'
import Notification from "../components/Notification.jsx";
import Cookies from "js-cookie";
import { useContext } from "react";
import { ProductContext } from "../store";
import "./../assets/scss/auth.scss";

const Auth = () => {
	const [isLogged, setIsLogged] = useState(false),
		[currentUrl, setCurrentUrl] = useState(null),
		[getStarted, setGetStarted]	= useState(false),
		mainContent = React.useRef(null),
		location = useLocation(),
		{
			successFlag,
		}	= useContext(ProductContext)

	useEffect(() => {
		document.documentElement.scrollTop = 0
		document.scrollingElement.scrollTop = 0
		if( mainContent.current ){
			mainContent.current.scrollTop = 0
		}
		setCurrentUrl(location.pathname)
	}, [location]);

	useEffect(() => {
		const Token = Cookies.get("_cb_search_token");
		if (Token) {
			setIsLogged(true)
		}
		if (!Cookies.get("_cb_referrer_url") && window.frames.top.document.referrer && window.frames.top.document.referrer !== Cookies.get("_cb_referrer_url")) {
			Cookies.set("_cb_referrer_url", window.frames.top.document.referrer)
		}
		if (!Cookies.get("_cb_start_url") && window.location.href) {
			Cookies.set("_cb_start_url", window.location.href)
		}
		if( !Cookies.get("_website_id") ){
			setGetStarted(true)
		}
	}, [])

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === "/auth") {
				return (
					<Route
						exact
						path={prop.path}
						element={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	}

	if (isLogged) {
		if( getStarted ){
			return <Navigate to="/getStarted/choosePlatform" replace />
		}else{
			return <Navigate to="/user/dashboard" replace />
		}
	}
	return (
		<>
			<div className="mainContent" ref={mainContent}>
				<div className="authLayoutWrapper">
					{/* Page content */}
					<div className={`authLayoutInnerWrapper ${(successFlag && currentUrl === "/auth/signup") ? "successSignUp" : ""}`}>
						<div className="authLayoutInnerContainer">
							<div className="authFormContainer">
								<div className={`authFormInnerWrapper ${(successFlag && currentUrl === "/auth/signup") ? "successSignUp" : ""}`}>
									<div className="logoWrapper">
										<div className="appLogo">
											<img src={require("./../assets/images/brand/conversion-box-logo.png")} alt="Logo" className="img-fluid" />
										</div>
										{currentUrl && (currentUrl === "/auth/login" || currentUrl === "/auth/forgotPassword" || currentUrl.indexOf('/auth/change-password') !== -1) ?
											<h1>Welcome Back</h1>
											: (currentUrl === "/auth/signup" && successFlag === false) ?
												<h1>Try for free</h1>
											: (successFlag && currentUrl === "/auth/signup") &&
												<h1>Sign-Up Success!</h1>
										}
									</div>
									<Routes>
										{getRoutes(authRoutes)}
										<Route path="*" element={<Navigate to="/auth/login" replace />} />
									</Routes>
								</div>
							</div>
						</div>
					</div>
					<Notification />
				</div>
			</div>
		</>
	);
}
export default Auth;