import React, { useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupText, Spinner, Form, Label } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from "yup";
import Cookies from 'js-cookie';
import { PostData } from '../../../services/FetchData';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGoogleLogin } from '@react-oauth/google';
import { useContext } from 'react';
import { ProductContext } from '../../../store';

const Signup = (props) => {
	const [showPassword, setShowPassword]	= useState(false)	
	//const [isWrongCredential, setIsWrongCredential]		= useState(false)
	const [isRegister, setIsRegister]	= useState(false)
	const {
			setCurrentWebsiteId,
		}	= useContext(ProductContext)

	useEffect(() => {
		document.title	= "Create a Free Account | ConversionBox App"
		return () => {
			document.title	= "ConversionBox App"
		}
	}, [])

	const createNewAccount = (values, actions) => {
		const userData = {
			name: values.name,
			email: values.email,
			password: values.password,
			referrer_url: Cookies.get("_cb_referrer_url") ? Cookies.get("_cb_referrer_url") : "",
			start_url: Cookies.get("_cb_start_url") ? Cookies.get("_cb_start_url") : ""
		}
		PostData('ms1', 'api/v1/user/signup', userData, false, false).then(result => {			
			if (result !== 'Invalid' && result !== undefined ) {
				if( result.status === "success" ){
					if( Cookies.get("_cb_referrer_url") ){
						Cookies.remove('_cb_referrer_url')
					}
					if( Cookies.get("_cb_start_url") ){
						Cookies.remove('_cb_start_url')
					}
					Cookies.set('_cb_search_token', result.data.accessToken)
					if( result.data.websites && Array.isArray(result.data.websites) && result.data.websites.length > 0 ){
						Cookies.set('_website_id', result.data.websites[0].websiteId)
						setCurrentWebsiteId(result.data.websites[0].websiteId)
					}
					setIsRegister(true)
					// setSuccessFlag(true)
				}else if ( result.status === "failure" ){
					toast.error("The user has been already registered or something went wrong please contact support team.")
				} else {
					toast.error("The user has been already registered or something went wrong please contact support team.")
				}
			}
			actions.setSubmitting(false);
		});
	};

	const gmailLogin = useGoogleLogin({
		onSuccess: (codeResponse) => {
			let userData = {
								code:codeResponse.code,
								referrer_url: Cookies.get("_cb_referrer_url") ? Cookies.get("_cb_referrer_url") : "",
								start_url: Cookies.get("_cb_start_url") ? Cookies.get("_cb_start_url") : ""
							}
			PostData("ms1",'api/v1/user/gmail-login', userData).then(result => {
					if (result !== 'Invalid' && result !== undefined) {
						if (result.status === "success") {
							// var inHalfADay = 1;
							// if( appURL === 'roundview.io/' ){
							// 	luckyOrange.customData( {email:result.data.userData.email, name:result.data.userData.firstName});
							// }
							if( Cookies.get("_cb_referrer_url") ){
								Cookies.remove('_cb_referrer_url')
							}
							if( Cookies.get("_cb_start_url") ){
								Cookies.remove('_cb_start_url')
							}
							Cookies.set('_cb_search_token', result.data.accessToken)
							if( result.data.websites && Array.isArray(result.data.websites) && result.data.websites.length > 0 ){
								Cookies.set('_website_id', result.data.websites[0].websiteId)
								setCurrentWebsiteId(result.data.websites[0].websiteId)
							}
							setIsRegister(true)
							//if (result.data.userData.onboardStatus === true || result.data.userData.createdUserId !== null ) { //|| result.data.userData.userRoleId == 2
							// if( result.message === "User Created Successfully" ){
							// 	setSuccessFlag(true)
							// }else{
							// 	toast.warn("Your account still not activated by our team. Please contact our support team.")
							// }
							//} else {
								//setOnBoardStatus(true)
							//}
						} else if( result.status === "failure" ){
							toast.error("Invalid Email or Password")
						} else {
							toast.error("Invalid Email or Password")
						}
					}
				});
		},
		onError: (error) => console.log('Login Failed:', error),
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
	})

	if( isRegister && Cookies.get('_cb_search_token') ){
		if( !Cookies.get("_website_id") ){
			return <Navigate to="/getStarted/choosePlatform" replace />
		}else{
			return <Navigate to="/user/dashboard" replace />
		}
	}


	return (
		<>
			<div className='socialIconWrapper'>
				<button className="gsi-material-button" style={{"width":"387.995px"}} onClick={() => gmailLogin()}>
					<div className="gsi-material-button-state"></div>
					<div className="gsi-material-button-content-wrapper">
						<div className="gsi-material-button-icon">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
								<path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
								<path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
								<path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
								<path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
								<path fill="none" d="M0 0h48v48H0z"></path>
							</svg>
						</div>
						<span className="gsi-material-button-contents">Sign up with Google</span>
						<span style={{"display": "none"}}>Sign up with Google</span>
					</div>
				</button>
				{/* <Button className='js-connect-google' onClick={() => gmailLogin()}>
					<i className="logo google"></i>
					Google
				</Button> */}
				{/* <Button className='js-connect-microsoft'>
					<i className='logo microsoft'></i>
					Microsoft
				</Button> */}
			</div>
			<div className='d-flex-row or align-items-center'>
				<div className='spacer'></div>
				<span>Or</span>
				<div className='spacer'></div>
			</div>
			<div className='authFormWrapper'>
				<div>
					<Formik
						initialValues={{ email: "", password: "", name: "" }}
						onSubmit={(values, actions) => {
							createNewAccount(values, actions)
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string().required("Name Required"),
							email: Yup.string().required("Email Required").email("Please enter a valid email address"),
							password: Yup.string().required("Password Required").min(8, "Password is Too short min 8 char"),
						})}
					>
						{props => {
							const {
								values,
								touched,
								errors,
								isSubmitting,
								//setSubmitting,
								handleChange,
								handleBlur,
								handleSubmit
							} = props;
							return (
								<Form role="form" onSubmit={handleSubmit} noValidate>
									<div className={`position-relative ${(errors.name && touched.name && "error_field")}`}>
										<Label className="input-group-alternative-label" >Name</Label>
										<InputGroup className="input-group-alternative">
											<Input
												placeholder="Name"
												type="text"
												name="name"
												value={values.name}
												onChange={handleChange}
												onBlur={handleBlur}
												className={(errors.name && touched.name && "error")}
											/>
										</InputGroup>
										{errors.name && touched.name && (
											<>
												<i className='fa-solid fa-circle-xmark errorIcon'></i>
												<div className="input-feedback">{errors.name}</div>
											</>
										)}
									</div>
									<div className={`position-relative ${(errors.email && touched.email && "error_field")}`}>
										<Label className="input-group-alternative-label" >
											Email
										</Label>
										<InputGroup className="input-group-alternative">
											<Input
												placeholder="Email"
												type="email"
												name="email"
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur}
												className={(errors.email && touched.email && "error")}
											/>
										</InputGroup>
										{errors.email && touched.email && (
											<>
												<i className='fa-solid fa-circle-xmark errorIcon'></i>
												<div className="input-feedback">{errors.email}</div>
											</>
										)}
									</div>
									<div className={`position-relative passwordField ${(errors.password && touched.password && "error_field")}`}>
										<Label className="input-group-alternative-label" >
											Password
										</Label>
										<InputGroup className="input-group-alternative">
											<Input
												placeholder="Password"
												type={showPassword ? "text" : "password"}
												name="password"
												value={values.password}
												onChange={handleChange}
												onBlur={handleBlur}
												className={(errors.password && touched.password && "error")}
												autofill="false"
											/>
											<InputGroupText>
												<div className="showPasswordWrapper" onClick={() => setShowPassword(!showPassword)}>
													{showPassword ?
														<span className="material-icons-outlined">visibility</span>
														:
														<span className="material-icons-outlined">visibility_off</span>
													}

												</div>
											</InputGroupText>
										</InputGroup>
										{errors.password && touched.password && (
											<>
												<i className='fa-solid fa-circle-xmark errorIcon'></i>
												<div className="input-feedback">{errors.password}</div>
											</>
										)}
										{/* {isWrongCredential && (
											<div className="input-feedback">
												The user has been already registered or something went wrong please contact support team.
											</div>
										)} */}
									</div>
									<div className="d-flex justify-content-center">
										{isSubmitting ? (
											<Button className="cta authCta" color="primary" type="button" disabled>
												<Spinner
													className="white mr-1"
													size="sm"
													color="light"
												/>
												<span className="ml-2 d-inline-block">Loading</span>
											</Button>
										) : (
											<Button className=" cta authCta" color="primary" type="submit">Create your account</Button>
										)}
									</div>
								</Form>)
						}}
					</Formik>
				</div>
			</div>
			<div className='d-flex-col'>
				<p className='mb-0 text-center'>Already have an account? <Link to='/auth/login' className='text-cta'>Login</Link></p>
				<div className="small text-inactive text-center">By continuing you agree to the <a className="link-underlined text-inactive" rel='noreferrer noopener' href="https://conversionbox.io/terms-of-service-agreement/" target="_blank">Terms of use</a> and <a className="link-underlined text-inactive" rel='noreferrer noopener' href="https://conversionbox.io/privacy-policy/" target="_blank">Privacy policy</a>.</div>
			</div>
		</>
	);
}

export default Signup;