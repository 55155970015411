import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Input, InputGroup, InputGroupText, Label, Row, Spinner } from 'reactstrap';
import { ProductContext } from '../../../store';
import { toast } from 'react-toastify';
import { PostData } from '../../../services/FetchData';

const Settings = (props) => {
	const [userDataLocal, setUserDataLocal] = useState({}),
		[errors, setErrors] = useState({}),
		[oldPassword, setOldPassword] = useState(""),
		[newPassword, setNewPassword] = useState(""),
		[oldPasswordShown, setOldPasswordShown] = useState(false),
		[newPasswordShown, setNewPasswordShown] = useState(false),
		[saveApiLoader, setSaveApiLoader]	= useState(false),
		{
			userData,
			setUserData,
		} = useContext(ProductContext)

	useEffect(() => {
		setUserDataLocal({ ...userData })
	}, [userData])

	const handleChange = (value, index) => {
		setUserDataLocal({
			...userDataLocal,
			[index]: value
		})
		if (index === "firstName") {
			if (value === "") {
				setErrors({
					...errors,
					firstName: "Please enter the first name"
				})
			} else {
				setErrors({
					...errors,
					firstName: null
				})
			}
		} else if (index === "lastName") {
			if (value === "") {
				setErrors({
					...errors,
					lastName: "Please enter the last name"
				})
			} else {
				setErrors({
					...errors,
					lastName: null
				})
			}
		}
	}

	const handlePassword = (value, index) => {
		if (index === "old_password") {
			setOldPassword(value)
			if (newPassword !== "" && value === "") {
				setErrors({
					...errors,
					old_password: "Password field is required"
				})
			} else if (value !== "" && value.length < 8) {
				setErrors({
					...errors,
					old_password: "Password is Too short min 8 char"
				})
			} else {
				let updateObject = {
					...errors,
					old_password: null
				}
				if (newPassword === "") {
					updateObject.new_password = null
				}
				setErrors(updateObject)
			}
		}

		if (index === "new_password") {
			setNewPassword(value)
			if (oldPassword !== "" && value === "") {
				setErrors({
					...errors,
					new_password: "Password field is required"
				})
			} else if (value !== "" && value.length < 8) {
				setErrors({
					...errors,
					new_password: "Password is Too short min 8 char"
				})
			} else {
				let updateObject = {
					...errors,
					new_password: null
				}
				if (oldPassword === "") {
					updateObject.old_password = null
				}
				setErrors(updateObject)
			}
		}
	}

	const saveChanges = () => {
		setSaveApiLoader(true)
		let errorFlag	= false
		if( userDataLocal.firstName === "" ){
			errorFlag	= true
			setErrors({
				...errors,
				firstName: "First name is required"
			})
		}else{
			setErrors({
				...errors,
				firstName: null
			})
		}
		if( userDataLocal.lastName === "" ){
			errorFlag	= true
			setErrors({
				...errors,
				firstName: "Last name is required"
			})
		}else{
			setErrors({
				...errors,
				firstName: null
			})
		}

		if( oldPassword || newPassword ){
			if( oldPassword === "" ){
				errorFlag	= true
				setErrors({
					...errors,
					old_password: "Password filed is required"
				})
			}else if( oldPassword.length < 8 ){
				errorFlag	= true
				setErrors({
					...errors,
					old_password: "Password is Too short min 8 char"
				})
			}else{
				setErrors({
					...errors,
					old_password:null
				})
			}

			if( newPassword === "" ){
				errorFlag	= true
				setErrors({
					...errors,
					new_password: "Password filed is required"
				})
			}else if( newPassword.length < 8 ){
				errorFlag	= true
				setErrors({
					...errors,
					new_password: "Password is Too short min 8 char"
				})
			}else{
				setErrors({
					...errors,
					new_password:null
				})
			}
		}

		if( errorFlag ){
			setSaveApiLoader(false)
			return
		}else{
			PostData("ms1", "api/v1/user/updateProfile", {...userDataLocal, newPassword: newPassword, oldPassword: oldPassword}).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						setNewPassword("")
						setOldPassword("")
						setUserData({
							...userData,
							firstName: userDataLocal.firstName,
							lastName: userDataLocal.lastName,
						})
						toast.info(result.message)
					}else if( result.status === "failure" ){
						toast.warn(result.message)
					}else if( result.status === "api_error" ){
						toast.warn(result.message.message)
					}
				}
				setSaveApiLoader(false)
			})
		}
	}

	return (
		<div className='settingPageWrapper'>
			<div className='d-flex justify-content-between'>
				<div className='d-flex align-items-start flex-column w-100'>
					<h1 className='pageMainHeading'>Details</h1>
				</div>
			</div>
			<div className='mt-4'>
				<div className='cardDisplay cardDisplayBody'>
					<div className='d-flex' id="userDetails">
						<div className='w-25 pr4'>
							<h2 className='displayHeadingInformation mb-1'>Person Information</h2>
						</div>
						<div className='w-75'>
							<div className='formFieldWrapper gap35 d-flex flex-column mt-3'>
								<Row>
									<Col xs={12} md={6}>
										<div className={`position-relative ${(errors.firstName && "error_field")}`}>
											<Label className="input-group-alternative-label" >First Name</Label>
											<InputGroup className="input-group-alternative">
												<Input
													placeholder="First Name"
													type="text"
													name="firstName"
													value={userDataLocal.firstName}
													onChange={(e) => handleChange(e.target.value, "firstName")}
													onBlur={(e) => handleChange(e.target.value, "firstName")}
													className={(errors.firstName)}
												/>
											</InputGroup>
											{errors.firstName && (
												<>
													<i className='fa-solid fa-circle-xmark errorIcon'></i>
													<div className="input-feedback">{errors.firstName}</div>
												</>
											)}
										</div>
									</Col>
									<Col xs={12} md={6}>
										<div className={`position-relative ${(errors.lastName && "error_field")}`}>
											<Label className="input-group-alternative-label" >Last Name</Label>
											<InputGroup className="input-group-alternative">
												<Input
													placeholder="Last Name"
													type="text"
													name="lastName"
													value={userDataLocal.lastName}
													onChange={(e) => handleChange(e.target.value, "lastName")}
													onBlur={(e) => handleChange(e.target.value, "lastName")}
													className={(errors.lastName)}
												/>
											</InputGroup>
											{errors.lastName && (
												<>
													<i className='fa-solid fa-circle-xmark errorIcon'></i>
													<div className="input-feedback">{errors.lastName}</div>
												</>
											)}
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={`position-relative`}>
											<Label className="input-group-alternative-label" >Email</Label>
											<InputGroup className="input-group-alternative">
												<Input
													placeholder="Email"
													type="text"
													name="email"
													disabled
													value={userDataLocal.email}
													className='disabled'
												/>
											</InputGroup>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</div>
					<hr className="cpBorderTop cbBorderGrey my-4"></hr>
					<div className='d-flex' id="userDetails">
						<div className='w-25 pr4'>
							<h2 className='displayHeadingInformation mb-1'>Update Password</h2>
						</div>
						<div className='w-75'>
							<div className='formFieldWrapper gap35 d-flex flex-column mt-3'>
								<Row>
									<Col xs={12} md={6}>
										<div className={`position-relative passwordField ${(errors.old_password && "error_field")}`}>
											<Label className="input-group-alternative-label" >Your current password</Label>
											<InputGroup className="input-group-alternative">
												<Input
													placeholder="Old Password"
													type={oldPasswordShown ? "text" : "password"}
													name="old_password"
													value={oldPassword}
													onChange={(e) => handlePassword(e.target.value, "old_password")}
													onBlur={(e) => handlePassword(e.target.value, "old_password")}
													className={(errors.old_password)}
													autoComplete={false}
												/>
												<InputGroupText>
													<div className="showPasswordWrapper" onClick={() => setOldPasswordShown(!oldPasswordShown)}>
														{oldPasswordShown ?
															<span className="material-icons-outlined">visibility</span>
															:
															<span className="material-icons-outlined">visibility_off</span>
														}

													</div>
												</InputGroupText>
											</InputGroup>
											{errors.old_password && (
												<>
													<i className='fa-solid fa-circle-xmark errorIcon'></i>
													<div className="input-feedback">{errors.old_password}</div>
												</>
											)}
										</div>
									</Col>
									<Col xs={12} md={6}>
										<div className={`position-relative passwordField ${(errors.new_password && "error_field")}`}>
											<Label className="input-group-alternative-label" >Your current password</Label>
											<InputGroup className="input-group-alternative">
												<Input
													placeholder="New Password"
													type={newPasswordShown ? "text" : "password"}
													name="new_password"
													value={newPassword}
													onChange={(e) => handlePassword(e.target.value, "new_password")}
													onBlur={(e) => handlePassword(e.target.value, "new_password")}
													className={(errors.new_password)}
													autoComplete={false}
												/>
												<InputGroupText>
													<div className="showPasswordWrapper" onClick={() => setNewPasswordShown(!newPasswordShown)}>
														{newPasswordShown ?
															<span className="material-icons-outlined">visibility</span>
															:
															<span className="material-icons-outlined">visibility_off</span>
														}

													</div>
												</InputGroupText>
											</InputGroup>
											{errors.new_password && (
												<>
													<i className='fa-solid fa-circle-xmark errorIcon'></i>
													<div className="input-feedback">{errors.new_password}</div>
												</>
											)}
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</div>
					<hr className="cpBorderTop cbBorderGrey my-4"></hr>
					<div className='d-flex align-items-center justify-content-end'>
						<Button color='primary' className='accountSettingSaveBtn cta autoWidth' disabled={saveApiLoader} onClick={() => saveChanges()}>
							{saveApiLoader &&
								<Spinner size={"sm"} />
							}
							Save Changes
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Settings;