import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Auth from './layouts/Auth';
import GetStarted from './layouts/GetStarted';
import User from './layouts/User';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/auth/*" element={<Auth />} />
				<Route path='/getStarted/*' element={<GetStarted />} />
				<Route path="/user/*" element={<User />} />
				<Route path="*" element={<Navigate to="/auth/login" replace />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
