import React, { useEffect, useRef } from 'react';
import Header from './Header';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { userRoutes } from '../Routes';

const Content = (props) => {
	const mainContent	= useRef(null),
		location	= useLocation()

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		if( mainContent.current ){
			mainContent.current.scrollTop = 0;
		}
	}, [location]);

	const getRoutes = () => {
		return userRoutes.map((prop, key) => {
			return (
				<Route
					exact
					path={prop.path}
					element={prop.component}
					key={key}
				/>
			);
		});
	}

	return (
		<div className='contentMainContainer' ref={mainContent}>
			<Header />
			<div className='pageContentWrapper'>
				<div className='pageContentInnerWrapper'>
					<Routes>
						{getRoutes()}
						<Route path="*" element={<Navigate to="/user/dashboard" replace />} />
					</Routes>
				</div>
			</div>
		</div>
	);
}

export default Content;