import ChangePassword from "./Views/Pages/Auth/ChangePassword";
import ForgotPassword from "./Views/Pages/Auth/ForgotPassword";
import Login from "./Views/Pages/Auth/Login";
import Signup from "./Views/Pages/Auth/Signup";
import ChoosePlatform from "./Views/Pages/GetStarted/ChoosePlatform";
import CollectWebsite from "./Views/Pages/GetStarted/CollectWebsite";
import Dashboard from "./Views/Pages/User/Dashboard";
import Settings from "./Views/Pages/User/Settings";


export const authRoutes = [
	{
		path: "/login",
		name: "Login",
		component: <Login />,
		layout: "/auth"
	},
	{
		path: "/signup",
		name: "Register",
		component: <Signup />,
		layout: "/auth"
	},
	{
		path: "/forgotPassword",
		name: "Forgot Password",
		component: <ForgotPassword />,
		layout: "/auth"
	},
	{
		path: "/change-password",
		name: "Change Password",
		component: <ChangePassword />,
		layout: "/auth"
	}
]

export const getStartedRoutes = [
	{
		path: "/choosePlatform",
		name: "Choose Platform",
		component: <ChoosePlatform />,
		layout: "/getStarted"
	},
	{
		path: "/website",
		name: "Website",
		component: <CollectWebsite />,
		layout: "/getStarted"
	}
]

export const userRoutes	= [
	{
		path: "/dashboard",
		name: "Overview",
		component: <Dashboard />,
		menuSvg: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home stl-text-accent-600 stl-ml-[-3px] stl-shrink-0"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>`,
		layout: "/user",
		type: "mainMenu"
	},
	{
		path: "/settings",
		name: "Settings",
		component: <Settings />,
		layout: "/user",
		menuSvg: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-settings stl-text-grey-400 stl-ml-[-3px] stl-shrink-0"><path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path><circle cx="12" cy="12" r="3"></circle></svg>`,
		type: "bottomMenu"
	},
]