const build	= "live";

let microURL = {
	ms1: "devbackend.",
	ms2: "micro2.",
	ms3: "micro3.",
	ms4: "micro4.",
	ms5: "micro5."
};

if( build === "live" ){
	microURL = {
					ms1: "backend.",
					ms2: "micro2.",
					ms3: "micro3.",
					ms4: "micro4.",
					ms5: "micro5."
				};
}

const serverURL = { http: "http://", https: "https://" };
// export const mainUrl2 = (props) => { // const appURL2 = props// }
const mainURL = { localURL: "conversionbox.io/", liveURL: "conversionbox.io/" };
export const appURL = mainURL.localURL;

// export const previewURL = appURL === "roundclicks.com/" ? `https://app.${appURL}` : `https://alpha.${appURL}`;

export const ms1URL = serverURL.https + microURL.ms1 + appURL;
export const ms2URL = serverURL.https + microURL.ms2 + appURL;
export const ms3URL = serverURL.https + microURL.ms3 + appURL;
export const ms4URL = serverURL.https + microURL.ms4 + appURL;
export const ms5URL = serverURL.https + microURL.ms5 + appURL;