import React, { useState } from "react";
import { ProductContext } from "./index";

const ProductProvider = props => {
	const [userData, setUserData]	= useState({}),
		[userLoader, setUserLoader]	= useState(true),
		[websiteData, setWebsiteData]	= useState({}),
		[currentWebsiteId, setCurrentWebsiteId]	= useState(null),
		[websiteLoader, setWebsiteLoader]	= useState(true);
	
	const store = {
		userData,
		setUserData,
		userLoader,
		setUserLoader,
		websiteData,
		websiteLoader,
		setWebsiteLoader,
		setWebsiteData,
		currentWebsiteId,
		setCurrentWebsiteId
	}
	return <ProductContext.Provider value={store}>{props.children}</ProductContext.Provider>;
}

export default ProductProvider;