import React from 'react';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { userRoutes } from '../Routes';

function Sidebar(props) {
	return (
		<div className='applicationsSidebar'>
			<aside className='applicationRootSidebar'>
				<div className='applicationSidebarScroll'>
					<div className='scrollIndicatorContent'>
						<header className='sidebarHeader'>
							<img src={require("./../assets/images/brand/conversion-box-logo.svg").default} alt='ConversionBox' className='img-fluid' />
						</header>
						<div className='navigationWrapper'>
							<nav>
								<ul className='mainNavigationList'>
									{userRoutes.map((userRoute, index) => {
										return (
											<React.Fragment key={index}>
												{userRoute.type === "mainMenu" &&
													<>
														{/* {userRoute.category && ( (index === 0) || (index > 0 && userRoutes[index-1].category !== userRoute.category) ) &&
															<div className='sectionTitle'>{userRoute.category}</div>
														} */}
														<NavLink
															to={userRoute.layout + userRoute.path}
															tag={NavLinkRRD}
															className={`navItem ${userRoute.itemClass ? userRoute.itemClass : ""}`}
															activeClassName="active"
															key={index}
														>
															{userRoute.icon ?
																<i className="material-symbols-outlined">{userRoute.icon}</i>
															: userRoute.menuSvg ?
																<div
																	dangerouslySetInnerHTML={{ __html: userRoute.menuSvg }}
																/>
															:
																<></>
															}
															<span className='rootNavText'>{userRoute.name}</span>
														</NavLink>
													</>
												}
											</React.Fragment>
										)
									})}
								</ul>
							</nav>
							<nav className='rootSidebarBottom'>
								<ul className='innerRootSidebarBottom'>
								{userRoutes.map((userRoute, index) => {
										return (
											<React.Fragment key={index}>
												{userRoute.type === "bottomMenu" &&
													<>
														{/* {userRoute.category && ( (index === 0) || (index > 0 && userRoutes[index-1].category !== userRoute.category) ) &&
															<div className='sectionTitle'>{userRoute.category}</div>
														} */}
														<NavLink
															to={userRoute.layout + userRoute.path}
															tag={NavLinkRRD}
															className={`navItem ${userRoute.itemClass ? userRoute.itemClass : ""}`}
															activeClassName="active"
															key={index}
														>
															{userRoute.icon ?
																<i className="material-symbols-outlined">{userRoute.icon}</i>
															: userRoute.menuSvg ?
																<div
																	dangerouslySetInnerHTML={{ __html: userRoute.menuSvg }}
																/>
															:
																<></>
															}
															<span className='rootNavText'>{userRoute.name}</span>
														</NavLink>
													</>
												}
											</React.Fragment>
										)
									})}
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</aside>
		</div>
	);
}

export default Sidebar;