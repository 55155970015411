import React, { useContext, useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { Navigate, Route, Routes } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import "./../assets/scss/get-started.scss";
import { getStartedRoutes } from '../Routes.js'
import { PostData } from '../services/FetchData.js';
import { ProductContext } from "../store";
import DetailSkeleton from '../components/Skeleton/DetailSkeleton.jsx';

const GetStarted = (props) => {
	const [isLogged, setIsLogged] = useState(true),
		{
			userData,
			setUserData,
			setUserLoader,
			userLoader,
			setCurrentWebsiteId
		}								= useContext(ProductContext);
	useEffect(() => {
		const Token = Cookies.get("_cb_search_token");
		if (!Token) {
			setIsLogged(false);
		} else {
			getUserDetails()
		}
	}, [])

	const getUserDetails = () => {
		setUserLoader(true);
		PostData("ms1", "api/v1/user/getUserDetail").then(result => {
			if (result !== "Invalid" && result !== undefined ) {
				if( result.status === "success" ){
					setUserData(result.data.user)
					if( result.data.websites && Array.isArray(result.data.websites) && result.data.websites.length > 0 && !Cookies.get("_website_id") ){
						Cookies.set('_website_id', result.data.websites[0].websiteId)
						setCurrentWebsiteId(result.data.websites[0].websiteId)
					}
				}
			}
			setUserLoader(false)
		});
	}

	const getRoutes = (routes) => {

		return routes.map((prop, key) => {
			if (prop.layout === "/getStarted") {
				return (
					<Route
						exact
						path={prop.path}
						element={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	}

	if (!isLogged) {
		return <Navigate to="/auth/login" replace />
	}

	if( !userLoader && Cookies.get("_website_id") ){
		return <Navigate to="/user/dashboard" replace />
	}
	return (
		<>
			{userLoader ?
					<DetailSkeleton />
			:
				<div className='getStartedWrapper'>
					<div className='getStartedHeader'>
						<div className='container'>
							<Row>
								<Col>
									<div className='d-flex gap3 align-items-center'>
										<svg width="24" height="24" viewBox="0 0 16 16" role="presentation" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clipRule="evenodd" d="M8.184 11.786c1.195-.902 3.182-2.46 4.562-3.842.87-.87 1.39-2.328 1.582-3.751a7.896 7.896 0 0 0 .05-1.822 2.92 2.92 0 0 0-.105-.555 1.04 1.04 0 0 0-.023-.065 1.069 1.069 0 0 0-.064-.022 2.908 2.908 0 0 0-.555-.105 7.863 7.863 0 0 0-1.82.05c-1.422.191-2.878.713-3.747 1.583-1.38 1.382-2.937 3.37-3.837 4.567-.083.111-.145.458.195.798l2.965 2.969c.34.34.686.278.797.195Zm6.731-10.7C14.167.335 9.56.452 7.411 2.602 5.98 4.034 4.39 6.068 3.489 7.268c-.427.567-.302 1.427.28 2.009l2.964 2.968c.581.581 1.44.707 2.007.28 1.198-.904 3.23-2.496 4.66-3.927 2.147-2.15 2.264-6.763 1.515-7.513Zm-1.784 4.117a2.347 2.347 0 0 1-2.345 2.348A2.347 2.347 0 0 1 8.44 5.203a2.347 2.347 0 0 1 2.346-2.348 2.347 2.347 0 0 1 2.345 2.348Zm-2.345 1.423a1.422 1.422 0 1 0-.002-2.844 1.422 1.422 0 0 0 .002 2.844Zm-7.69-4.69c-.4-.045-.794.118-1.045.433L.258 4.61a1.174 1.174 0 0 0 .787 1.9l1.456.163a.463.463 0 0 0 .102-.92l-1.456-.162A.25.25 0 0 1 .98 5.19l1.792-2.243a.249.249 0 0 1 .222-.091l2.35.26a.463.463 0 1 0 .102-.918l-2.35-.262Zm10.882 12.008c.31-.249.474-.638.434-1.034l-.27-2.713a.462.462 0 0 0-.92.092l.271 2.713a.249.249 0 0 1-.092.22l-2.244 1.797a.249.249 0 0 1-.403-.167l-.162-1.458a.462.462 0 0 0-.918.101l.162 1.46a1.172 1.172 0 0 0 1.898.786l2.244-1.797ZM2.628 11.12a.463.463 0 0 0-.654-.654l-.768.77a.463.463 0 0 0 .653.653l.768-.769Zm2.842 2.193c.18.18.18.473 0 .654l-.768.769a.462.462 0 1 1-.653-.655l.768-.768a.46.46 0 0 1 .653 0Zm-1.421-.77a.463.463 0 0 0-.654-.654l-2.19 2.193a.463.463 0 0 0 .654.654l2.19-2.193Z" fill="currentColor"></path></svg>
										<h1>Get Started</h1>
									</div>
								</Col>
							</Row>
						</div>
					</div>
					<div className='getStartedContentWrapper'>
						<Routes>
							{getRoutes(getStartedRoutes)}
							<Route path="*" element={<Navigate to="/getStarted/choosePlatform" replace />} />
						</Routes>
					</div>
				</div>
			}
		</>
	);
}

export default GetStarted;