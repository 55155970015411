import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap'

const ChoosePlatform = (props) => {
	const [selectedPlatform, setSelectedPlatform]	= useState(null),
		[redirectWebsite, setRedirectWebsite]	= useState(false);
	
	useEffect(() => {
		if( selectedPlatform === "magento" ){
			setRedirectWebsite(true)
		}else{
			setRedirectWebsite(false)
		}
	}, [selectedPlatform])

	if( redirectWebsite ){
		return <Navigate to="/getStarted/website" replace />
	}

	return (
		<>
			<section className='choosePlatformHeader'>
				<div className='container'>
					<Row className="align-items-center justify-content-center">
						<Col xs={"auto"}>
							<h2 className='mt-5'>Choose Platform</h2>
						</Col>
					</Row>
					<div className='d-flex gap10 align-item-center justify-content-center mt-5'>
						<div className={`platformWrapper ${selectedPlatform === "shopify" ? "selectedPlatform" : ""}`} onClick={() => setSelectedPlatform("shopify")}>
							<svg fill="#95BF47" width="200px" height="200px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path fill='#95BF47' d="m14.49 20.937 5.381-1.166S17.93 6.633 17.914 6.546c-.016-.086-.086-.144-.158-.144s-1.439-.101-1.439-.101-.949-.949-1.064-1.05c-.027-.029-.057-.043-.086-.058l-.677 15.744zm.446-15.772c-.016 0-.043.014-.057.014-.016 0-.217.059-.533.158-.318-.919-.879-1.768-1.871-1.768h-.086c-.289-.361-.633-.519-.936-.519-2.316 0-3.426 2.892-3.77 4.359-.892.275-1.538.476-1.613.505-.504.158-.517.172-.574.648-.057.344-1.367 10.489-1.367 10.489l10.117 1.899.69-15.785zm-2.635.704v.102c-.559.173-1.178.36-1.783.547.346-1.323.992-1.972 1.553-2.217.146.375.23.878.23 1.568zm-.92-2.2c.1 0 .201.028.303.102-.732.344-1.539 1.222-1.871 2.978a59.11 59.11 0 0 1-1.411.432c.389-1.339 1.325-3.512 2.979-3.512zm.402 7.812s-.604-.315-1.322-.315c-1.08 0-1.123.676-1.123.849 0 .921 2.418 1.28 2.418 3.453 0 1.712-1.08 2.806-2.547 2.806-1.756 0-2.648-1.094-2.648-1.094l.475-1.554s.922.792 1.697.792a.693.693 0 0 0 .721-.69c0-1.209-1.986-1.268-1.986-3.252 0-1.669 1.195-3.295 3.627-3.295.936 0 1.395.272 1.395.272l-.707 2.028zm.922-7.281c.518.06.85.648 1.064 1.311-.258.087-.547.173-.863.273v-.187c0-.561-.072-1.022-.201-1.397z"/>
							</svg>
							<h3 className='mt-3'>Shopify</h3>
						</div>
						<div className='platformWrapper' onClick={() => setSelectedPlatform("magento")}>
							<svg fill="#f46f25" width="200px" height="200px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
								<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
								<g id="SVGRepo_iconCarrier">
									<path d="m12 22.019-3.717-2.146V9.863l2.479-1.43v10.01l1.238.753 1.238-.753V8.434l2.479 1.43v10.01L12 22.019zm8.666-15.014v10.009l-2.475 1.43V8.434L12 4.861 5.807 8.434v10.01l-2.473-1.43V7.005L12 2l8.666 5.005z"></path>
								</g>
							</svg>
							<h3 className='mt-3'>Magento</h3>
						</div>
					</div>
				</div>
			</section>
			{selectedPlatform === "shopify" &&
				<section className='shopifyAppInstallationGuide mt-5'>
					<div className='container'>
						<div className='shopifyInstructionManual'>
							<h3>Install Conversion Box Search For Shopify</h3>
							<p className='installMaunalText'><span>Connect your Shopify store to ConversionBox Search with a </span><a target="_blank" href="https://apps.shopify.com/conversionbox-search" rel="noopener noreferrer"><span>couple of clicks.</span><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-external-link stl-ml-1 stl-shrink-0"><path d="M15 3h6v6"></path><path d="M10 14 21 3"></path><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path></svg></a></p>						
							<Row className='mt-5'>
								<Col xs={12} lg={6}>
									<img src={require("./../../../assets/images/shopify-store.png")} alt='Shopify Store' className='img-fluid shopifyStore' />
								</Col>
								<Col xs={12} lg={6}>
									<img src={require("./../../../assets/images/shopify-install-manual.png")} alt='Shopify Store' className='img-fluid shopifyStore' />
								</Col>
							</Row>
						</div>
					</div>
				</section>
			}
		</>
	)
}

export default ChoosePlatform
